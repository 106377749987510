var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { attrs: { id: "adminNav" } }, [
    _c("ul", { staticClass: "mainMenu gnb" }, [
      _vm._m(0),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/admin/users" } }, [
            _c("i", { staticClass: "material-icons" }, [_vm._v("person_add")]),
            _vm._v(" 회원 관리 ")
          ])
        ],
        1
      ),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/admin/payments" } }, [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v("attach_money")
            ]),
            _vm._v(" 결제 관리 ")
          ])
        ],
        1
      ),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/admin/demoList" } }, [
            _c("i", { staticClass: "material-icons" }, [_vm._v("list_alt")]),
            _vm._v(" 데모 관리 ")
          ])
        ],
        1
      ),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/admin/qna" } }, [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v("support_agent")
            ]),
            _vm._v(" 문의사항 관리 ")
          ])
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: { to: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.logout.apply(null, arguments)
                }
              }
            },
            [
              _c("i", { staticClass: "material-icons" }, [_vm._v("logout")]),
              _vm._v(" 로그아웃 ")
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dashLogo" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          staticClass: "logo1",
          attrs: {
            src: require("@/assets/images/reviewmind/logo.png"),
            alt: "로고"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }